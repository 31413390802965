let answer = document.querySelectorAll('#answer-querstion')

for (let i = 0; i < answer.length; i+=1) {
    answer[i].addEventListener('click', (e) => {
        if (answer[i].classList.value.split(' ').find(el => el === 'open')) {
            answer[i].classList.remove('open')   
            return
        }
        for (answ of answer) {
            if (answ.classList.value.split(' ').find(el => el === 'open')) {
                answ.classList.remove('open')
            }
        }
        answer[i].classList.toggle('open')     
    })
}
